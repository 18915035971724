import Cookies from 'js-cookie';
import { AxiosError, AxiosResponse } from 'axios';

import httpClient from '@/api/http-client';

import { COOKIE_ACCESS_TOKEN_KEY, COOKIE_INVITE_KEY } from './const';
import { AuthData } from './login';

let refreshTokenInterceptor: number | undefined;
let refreshPromise: Promise<AxiosResponse<AuthData>> | undefined;

/**
 * This ensures that only one refresh token request will be sent
 */
const refreshToken = (refresh_token: string) => {
  if (!refreshPromise) {
    refreshPromise = httpClient
      .post<AuthData>('/refresh', undefined, {
        headers: {
          Authorization: `Bearer ${refresh_token}`,
        },
      })
      .then((res) => {
        refreshPromise = undefined;
        return res;
      })
      .catch((error) => {
        refreshPromise = undefined;
        throw error;
      });
  }

  return refreshPromise;
};

const removeRefreshTokenInterceptor = () => {
  if (refreshTokenInterceptor) {
    httpClient.interceptors.response.eject(refreshTokenInterceptor);
    refreshTokenInterceptor = undefined;
  }
};

const addRefreshTokenInterceptor = (refresh_token: string) => {
  removeRefreshTokenInterceptor();

  refreshTokenInterceptor = httpClient.interceptors.response.use(
    (res) => res,
    async (error: AxiosError) => {
      if (error.response?.status !== 401) {
        throw error;
      }

      // Remove it because of possible infinite loop
      removeRefreshTokenInterceptor();

      return refreshToken(refresh_token)
        .then(async (res) => {
          const access_token = res.data.access_token;
          setAccessToken(access_token, false);
          error.config.headers['Authorization'] = `Bearer ${access_token}`;

          const orgRes = await httpClient.request(error.config);
          setAccessToken(access_token);
          return orgRes;
        })
        .catch((refreshTokenError) => {
          console.error(refreshTokenError);

          removeAccessToken();

          window.location = window.location;
        });
    }
  );
};

export const getAccessToken = () => Cookies.get(COOKIE_ACCESS_TOKEN_KEY);

export const setAccessToken = (token: string, initRefreshTokenInterceptor = true) => {
  httpClient.defaults.headers.common.Authorization = `Bearer ${token}`;

  if (initRefreshTokenInterceptor) {
    addRefreshTokenInterceptor(token);
  }

  Cookies.set(COOKIE_ACCESS_TOKEN_KEY, token, { expires: 1 });
};

export const removeAccessToken = () => {
  removeRefreshTokenInterceptor();
  delete httpClient.defaults.headers.common.Authorization;
  Cookies.remove(COOKIE_ACCESS_TOKEN_KEY);
};

export interface InviteTokenObject {
  invite_token: string;
  invite_id: number;
  invite_type: string;
}

export const getInviteObject = (): InviteTokenObject | undefined => {
  const inviteString = Cookies.get(COOKIE_INVITE_KEY);
  if (inviteString) {
    const parsed = JSON.parse(inviteString);
    if (parsed) {
      return {
        invite_id: Number(parsed.invite_id) || 0,
        invite_token: String(parsed.invite_token) || '',
        invite_type: String(parsed.invite_type) || '',
      };
    }
  }
  return undefined;
};

export const setInviteObject = (invite: InviteTokenObject) => {
  Cookies.set(COOKIE_INVITE_KEY, JSON.stringify(invite), { expires: 7 });
};

export const removeInviteObject = () => {
  Cookies.remove(COOKIE_INVITE_KEY);
};
