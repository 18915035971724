import axios from 'axios';
import config from '@/config';
import { isArray, isObject, mapValues, isPlainObject, isNull } from 'lodash';

const mapNullToUndefined = (value: unknown): any => {
  if (isArray(value)) {
    return value.map(mapNullToUndefined);
  }

  if (isObject(value) && isPlainObject(value)) {
    return mapValues(value, mapNullToUndefined);
  }

  if (isNull(value)) {
    return undefined;
  }

  return value;
};

const api = axios.create({
  baseURL: config.API_BASE,
  headers: {
    common: {
      Authorization: '',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  },
  responseType: 'json',
});

api.interceptors.response.use(
  (value) => mapNullToUndefined(value),
  (err) => {
    throw mapNullToUndefined(err);
  }
);

export default api;
